<template>
  <div class="recently-featured">
    <h2 class="title">
      {{ $t('recentlyfeatured') }}
    </h2>
    <div class="logos-wrapper">
      <img :src="cloudinary.EDUCATION_TODAY_LOGO" width="211" />
      <img :src="cloudinary.EDUCATION_CNN_LOGO" width="211" />
      <img :src="cloudinary.EDUCATION_CNBC_LOGO" width="211" />
      <img :src="cloudinary.EDUCATION_WSJ_LOGO" width="211" />
    </div>
  </div>
</template>

<script>
import cloudinary from '@/constants/cloudinary';

export default {
  created() {
    this.cloudinary = cloudinary;
  },
};
</script>

<style lang="scss" scoped>
.recently-featured {
  margin-bottom: 2rem;
  text-align: center;
  display: none;

  @include bp-medium {
    display: block;
  }

  .title {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .logos-wrapper {
    display: flex;
    justify-content: space-evenly;

    img {
      max-width: 13.1875rem;
      width: 25%;
    }
  }
}
</style>
