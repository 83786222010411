<template>
  <div :class="wrapper">
    <template>
      <section class="section split" v-if="!isTextFirst">
        <div class="split-img">
          <img :src="imageUrl" :alt="imageAlt" width="370" />
        </div>
        <div class="split-text">
          <div class="solution-hero-h1">
            {{ titleText }}
          </div>
          <ul role="list" class="items">
            <li
              v-for="(item, index) in subDetails"
              :key="index"
              class="label description"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </section>
      <section class="section split reverse" v-if="isTextFirst">
        <div class="split-img">
          <img :src="imageUrl" :alt="imageAlt" width="370" />
        </div>
        <div class="split-text">
          <div class="solution-hero-h1">
            {{ titleText }}
          </div>
          <ul role="list" class="items">
            <li
              v-for="(item, index) in subDetails"
              :key="index"
              class="label description"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SolutionCard',
  props: ['titleText', 'subDetails', 'imageUrl', 'imageAlt', 'isTextFirst'],
  data() {
    return {
      title: 'Solution',
      wrapper: 'solution-component-wrapper',
      thisPage: {},
      childDataLoaded: false,
    };
  },
};
</script>

<style scoped lang="scss">
.solution-component-wrapper {
  max-width: 68rem;
  margin: 0 auto;
}
.solution-hero-h1 {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.25em;
  font-weight: 700;
  @include bp-mediumsmall {
    font-size: 1.375rem;
  }
  @include bp-xlarge {
    font-size: 2rem;
  }
  @include bp-medium {
    margin-bottom: 1.5rem;
  }
}
.split {
  padding-top: 0;
  margin-top: 3rem;
  @include bp-medium {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6.5rem;
  }

  & > div {
    flex: 0 1 48%;
  }
}
.reverse {
  @include bp-medium {
    .split-img {
      order: 2;
    }
  }
}
.split-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    order: 2;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    line-height: 3rem;
  }
  .description {
    order: 3;
    font-size: 1rem;
    line-height: 1.7rem;
  }
}
.split-img {
  display: block;
  min-width: auto;
  @include bp-xlarge {
    min-width: 26rem;
  }
  img {
    width: 100%;
    display: block;
    margin: auto;
    // max-width: 15.625rem;
    max-width: 23.125rem;
  }
}
.button-link {
  order: 4;
  margin-top: 1.5rem;
}
</style>
