<template>
  <div class="btn_wrapper">
    <template>
      <p class="description">
        <btn
          custom-class="btn-request-demo"
          :text="$t('requestDemo')"
          href="contact-us"
          target="_blank"
        >
        </btn>
      </p>
    </template>
  </div>
</template>

<script>
import Btn from '@/components/basic/Btn.vue';

export default {
  name: 'DemoButton',
  components: {
    Btn,
  },
  data() {
    return {
      title: 'DemoButton',
      wrapper: 'demo_component_wrapper',
    };
  },
};
</script>

<style scoped lang="scss">
.btn_wrapper {
  width: 100%;
}
.btn-request-demo {
  color: #ffffff;
  padding: 1rem;
  border-radius: 6.25rem;
  transition: all 240ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: #0073ff;
  order: 4;
  width: 100%;
  padding: 0.75rem 1.5rem;
  @include bp-mediumsmall {
    width: auto;
  }
  font-size: 0.9375rem;
  text-align: center;
  font-weight: 700;
}
</style>
