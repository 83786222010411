<template>
  <div :class="wrapper">
    <template>
      <hero
        :heroImgSrc="cloudinary.EDUCATION_ILLUSTRATION"
        :heroTitle="$t('educationTitle')"
        :heroDescrip="$t('educationTitleDes')"
      />
      <solution-card
        :titleText="$t('educationTest')"
        v-bind:subDetails="$t('educationTestDes')"
        v-bind:imageUrl="cloudinary.EDUCATION_TEST_ADMINISTRATION"
        imageAlt=""
        v-bind:isTextFirst="true"
      />

      <solution-card
        :titleText="$t('educationData')"
        v-bind:subDetails="$t('educationDataDes')"
        v-bind:imageUrl="cloudinary.EDUCATION_DATA_REPORTING"
        imageAlt=""
        v-bind:isTextFirst="false"
      />

      <solution-card
        :titleText="$t('educationPatient')"
        v-bind:subDetails="$t('educationPatientDes')"
        v-bind:imageUrl="cloudinary.EDUCATION_PATIENT_EXPERIENCE"
        imageAlt=""
        v-bind:isTextFirst="true"
      />

      <solution-card
        :titleText="$t('educationPassport')"
        v-bind:subDetails="$t('educationPassportDes')"
        v-bind:imageUrl="cloudinary.EDUCATION_DAILY_PASSPORT"
        imageAlt=""
        v-bind:isTextFirst="false"
      />

      <section class="section">
        <case-management-card />
      </section>

      <section class="section last">
        <featured-logos />
      </section>
    </template>
    <div class="mobile">
      <AppStoreLinks />
    </div>
  </div>
</template>

<script>
import AppStoreLinks from '@/components/basic/AppStoreLinks.vue';
import cloudinary from '@/constants/cloudinary';
import FeaturedLogos from '@/components/basic/FeaturedLogos';
import Hero from '@/components/basic/Hero';
import SolutionCard from '@/views/SolutionCard.vue';
import CaseManagementCard from '@/views/CaseManagementCard.vue';
import urls from '@/constants/urls';
import { butter } from '@/buttercms';

export default {
  name: 'Education',
  components: {
    AppStoreLinks,
    CaseManagementCard,
    FeaturedLogos,
    Hero,
    SolutionCard,
  },
  data() {
    return {
      title: 'Education',
      wrapper: 'education_component_wrapper',
      thisPage: {},
      urls: {},
      childDataLoaded: false,
    };
  },
  methods: {
    fetchCms() {
      const locale = this.$route.params.locale;
      butter.page
        .retrieve('home', 'home', { locale: locale })
        .then(resp => {
          this.thisPage = resp.data.data;
          this.childDataLoaded = true;
        })
        .catch(function(resp) {
          console.log(resp);
        });
    },
  },
  computed: {
    getCardComponent() {
      return this.childDataLoaded ? this.thisPage.fields.card_component : [];
    },
  },
  created() {
    this.fetchCms();
    this.urls = urls;
    this.cloudinary = cloudinary;
  },
};
</script>

<style scoped lang="scss">
@import '../styles/global/marketing.scss';

.section {
  @extend %section;
}

.section.last {
  @extend %section-last;
}

.byu {
  background-color: rgba(92, 138, 254, 0.08);
  border-radius: 2rem;
  padding: 4rem 3.875rem;
  min-height: 30rem;
  .title {
    color: #161616;
    font-size: 1.375rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include bp-xlarge {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
}

.split {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include bp-xlarge {
    flex-direction: row;
  }

  & > div {
    @include bp-xlarge {
      width: 50%;
    }
  }
}

.mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0 -2px 4px rgba(8, 35, 48, 0.1);
  @include bp-medium {
    display: none;
  }
  .stores {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem;
  }
  @include bp-mediumsmall {
    display: none;
  }
}
</style>
