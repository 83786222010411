<template>
  <div class="hero">
    <div class="split-text">
      <h1 class="title">
        {{ heroTitle }}
      </h1>
      <div>
        <p class="description">
          {{ heroDescrip }}
        </p>
      </div>
      <demo-button />
    </div>
    <div class="split-img">
      <img :src="heroImgSrc" />
    </div>
  </div>
</template>

<script>
import DemoButton from '@/views/DemoButton.vue';

export default {
  components: {
    DemoButton,
  },
  props: {
    heroImgSrc: {
      type: String,
      default() {
        return '';
      },
    },
    heroTitle: {
      type: String,
      default() {
        return '';
      },
    },
    heroDescrip: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-top: 0;

  @include bp-xlarge {
    flex-direction: row;
    text-align: left;
  }

  & > div {
    flex: 0 1 48%;
  }

  .title {
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include bp-xlarge {
      font-size: 3rem;
      line-height: 3.75rem;
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  }

  .description {
    font-size: 1rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
  }
}

.split-text {
  display: flex;
  flex-direction: column;

  @include bp-medium {
    min-width: 26rem;
  }
}

.split-img {
  max-width: 22.8125rem;
  @include bp-xlarge {
    max-width: 35.5rem;
  }

  img {
    width: 100%;
    display: block;
  }
}
</style>
